import { useContext, useCallback, useMemo, useState, useEffect } from "react";
import * as d3 from "d3";
import { FontContext, IArea } from "./Common";
import { ISolution } from "../models/Solution";
import { ReactComponent as FortesLogo } from "../Forteslogo_REG_black.svg";
import { floatToStr } from "../models/Common";
import React from "react";
import { ProductType } from "../models/Product";

export interface IInfoTableProps {
    area: IArea,
    solution: ISolution,
}
  
export function InfoTable({ area, solution }: IInfoTableProps) {
    const { size: fontSize } = useContext(FontContext);
    const [fontSizeUsed, setFontSize] = useState(fontSize)
    useEffect(() => {
        setFontSize(fontSize)
    }, [solution.product, solution.customer, solution.status, fontSize])
    const paddingX = 10;

    const date = new Date(Date.now());

    const rows = 7;
    const rowHeight = area.height / rows;
    const logoPadding = 1.2 * fontSizeUsed;

    const cellX = useMemo(() => [ area.offsetX, area.offsetX + 0.3 * area.width, area.offsetX + 0.55 * area.width ], [area]);
    const rowWidth = {
        l: 0.7  * area.width,
        m: 0.45 * area.width,
        s: 0.3  * area.width
    }
    const cellY = useMemo(() => {
        const c = [ area.offsetY ];
        for (let i = 1; i < rows; ++i)
            c.push(c[i - 1] + rowHeight);
        return c;
    }, [area, rowHeight]);

    const lastX = area.offsetX + area.width;
    const lastY = area.offsetY + area.height;

    const product = solution.product;
    const sensor = solution.sensor;
    const drawingNumber = solution.product.drawingNumber;
    const customer = solution.customer;
    const status = solution.status;
    
    const diagonal = floatToStr(sensor.diagonal);
    
    const titleStr = titleStrGen(
        product.title,
        product.type,
        solution.glass.withGlass,
        solution.sensor.withSensor,
        diagonal
    )

    let fontSizeTemp = fontSizeUsed;
    [
        {text: `Product: ${titleStr}`, maxWidth: rowWidth.l},
        {text: `Customer: ${customer.company}`, maxWidth: rowWidth.l},
        {text: `Quantity: ${`${product.pieces}`}`, maxWidth: rowWidth.s},
        {text: `Drawing number: ${!drawingNumber ? 'X' : `${drawingNumber}`}`, maxWidth: rowWidth.m},
        {text: `Created by: ${customer.creator}`, maxWidth: rowWidth.l},
        {text: `Approved by customer: ${status.isApproved ? status.customerApprover : 'X'}`, maxWidth: rowWidth.l},
        {text: `Approved by FORTES: ${status.isApproved ? status.fortesApprover : 'X'}`, maxWidth: rowWidth.l},
    ].forEach((t: {text: string, maxWidth: number}) => {
        fontSizeTemp = Math.min(fontSizeTemp, compareTextWidth(fontSizeTemp, t.text, t.maxWidth))
    })
    if(fontSizeUsed !== fontSizeTemp) {
        setFontSize(fontSizeTemp)
    }
    
    const cell = useCallback((label: string, text: string, col: number, row: number, colSpan = 1, rowSpan = 1) => {
        const pRow = d3.path();
        pRow.moveTo(cellX[col], cellY[row]);
        pRow.lineTo(cellX[col + colSpan] ?? lastX, cellY[row]);

        const pCol = d3.path();
        pCol.moveTo(cellX[col], cellY[row]);
        pCol.lineTo(cellX[col], cellY[row + rowSpan] ?? lastY);

        return <>
                <path stroke="#000" strokeWidth="1" d={pRow.toString()} />
                <path stroke="#000" strokeWidth="1" d={pCol.toString()} />
                <text x={cellX[col] + paddingX} y={cellY[row] + 0.6 * rowHeight}>
                    <tspan fontSize={fontSizeUsed}>{label} </tspan>
                    <tspan fontSize={1.2 * fontSizeUsed}>{text}</tspan>
                </text>
            </>
    }, [cellX, cellY, fontSizeUsed, lastX, lastY, paddingX, rowHeight]);

    return <g>
        <rect x={area.offsetX} y={area.offsetY} width={area.width} height={area.height} stroke="#000" fill="none" />

        <FortesLogo x={area.offsetX + logoPadding} y={area.offsetY + logoPadding}
            width={cellX[1] - cellX[0] - 2 * logoPadding} height={cellY[5] - cellY[0] - 2 * logoPadding} />

        {cell("Product:", titleStr, 1, 0, 2)}
        {cell("Customer:", customer.company, 1, 1, 2)}
        {cell("Quantity:", `${product.pieces}`, 1, 2)}
        {cell("Drawing number:", !drawingNumber ? 'X' : `${drawingNumber}`, 2, 2)}
        {cell("Created by:", customer.creator, 1, 3, 2)}
        {cell("Approved by customer:", status.isApproved ? status.customerApprover : 'X', 1, 4, 2)}
        {cell("Approved by FORTES:", status.isApproved ? status.fortesApprover : 'X', 1, 5, 2)}
        {cell("Date:", `${date.toLocaleDateString('cs')}`, 0, 6)}
        {cell("", "www.fortesinteractive.com", 0, 5)}
        {cell("", "DIMENSIONS ARE IN MILIMETERS", 1, 6, 2)}
    </g>;
}

function compareTextWidth(fontSize: number, text: string, maxWidth: number) : number {
    while(textWidth(fontSize, text) > maxWidth) fontSize--
    return fontSize
}

function textWidth(fontSize: number, text: string) : number {
    return text.split('').length * fontSize / 1.4
}

function titleStrGen(
    title: string,
    productType: ProductType,
    withGlass: boolean,
    withSensor: boolean,
    diagonel: string
) : string {

    if(withSensor && withGlass) {
        if(title !== "") {
           return `${productType}, ${title}` 
        }
        return `${productType} with ${diagonel} inch sensor`
    }

    if(withGlass) {
        if(productType === ProductType.FortouchO) {
            if(title !== "") {
                return `Double cover glass, ${title}`
            }
            return `Double cover glass`
        }

        if(title !== "") {
            return `Cover glass, ${title}`
        }
        return `Cover glass`
    } 

    if(withSensor) {
        if(title !== "") {
            return `Sensor, ${title}`
        }
        return `${diagonel} inch sensor`
    }

    return "Incorrect order (please report bug)"
}