import { Field, FieldProps, useFormikContext } from "formik";
import { StyledRawSwitchLabeled } from "./Checkbox";
import { PairedInput } from "./PairedInput";
import { ISolution } from "../models/Solution";
import { useEffect, useState } from "react";
import { SolutionField } from "./SolutionField";
import { ProductType } from "../models/Product";


export function OtherSettings(props: {type: ProductType}) : JSX.Element {
  const { values, setFieldValue } = useFormikContext<ISolution>();
  const [extraLayers, setExtraLayer] = useState<boolean>(
    values.customization.otherSettings.IR ||
    values.customization.otherSettings.UV ||
    values.customization.otherSettings.PF
  )

  const settings = [
    <StyledRawSwitchLabeled
      checked={values.customization.otherSettings.UV}
      onChange={(event) => setFieldValue('customization.otherSettings.UV', event.target.checked)}
      name='customization.otherSettings.UV'
      label='UV protection' paired={true}
    />,
    <StyledRawSwitchLabeled
      checked={values.customization.otherSettings.IR}
      onChange={(event) => setFieldValue('customization.otherSettings.IR', event.target.checked)}
      name='customization.otherSettings.IR'
      label='IR (solar) protection' paired={true}
    />,
  ]
  if(props.type !== ProductType.FortouchO) settings.push(    
    <StyledRawSwitchLabeled
      checked={values.customization.otherSettings.PF}
      onChange={(event) => setFieldValue('customization.otherSettings.PF', event.target.checked)}
      name='customization.otherSettings.PF'
      label='Protective foil' paired={true}
    />
  )

  useEffect(() => {
    setExtraLayer(
      values.customization.otherSettings.IR ||
      values.customization.otherSettings.UV ||
      values.customization.otherSettings.PF
    )
  }, [values.customization.otherSettings])

  return <>
    <Field component={(fieldProps: FieldProps<number, ISolution>) =><>
      <PairedInput bigLabel="Other settings" elements={settings} />
    </>}/>

    {extraLayers && 
      <SolutionField name="customization.extraLayers" />
    }
  </>
}