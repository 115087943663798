import { useContext, useMemo, useState } from "react";
import { ScaleContext } from "./Common";
import { IRoundedRectShape, getCorners } from "../models/Rectangle";
import InlineSVG from "react-inlinesvg";

export interface IImgRect {
  shape: IRoundedRectShape,
  file: File,
  canvasW : number
}

export function ImgRectangle({ shape, file, canvasW }: IImgRect) {

  const [svgData, setSvgData] = useState<string>("");

  const reader = new FileReader()
  reader.onload = async (e) => { 
    if(e.target){
      if(e.target.result){
        const text = (e.target.result)

        setSvgData(text.toString())

      }
    }
  };
  reader.readAsText(file)


  
  
  const scale = useContext(ScaleContext);
  
  const corners = useMemo(() => getCorners(shape, scale), [shape, scale]);
  
  const imgHeight = corners[3][1] - corners[0][1]
  
  const imgPosX = ((corners[1][0] + corners[0][0])/2) - canvasW/2
  const imgPosY = ((corners[1][1]))

  //@ts-ignore
  var x = <InlineSVG src={svgData} height={imgHeight} width=""/> 

  return <svg x={imgPosX} y={imgPosY}>
      { (x !== undefined) && x }
    
    </svg>
    
  
}

