import { getIn } from "formik";
import { IItemProps, IProps } from "../MuiField";
import { applyRuleOnChange, getOnChangeSelectInput } from "../../models/Form";
import { Box, Grid, MenuItem, outlinedInputClasses, Select, } from "@mui/material";
import { StyledMuiFieldLabel } from "./MuiFIeldLabel";
import { useState } from "react";
import { Surface } from "../../models/Customization";
import { SolutionField } from "../SolutionField";
import { ProductType } from "../../models/Product";
import { PairedInput } from "../PairedInput";

export function StyledSelectMuiFiled(props: IProps) : JSX.Element {
    
    
    
  const disabled = props.disabled ?? false;
  const fullWidth = props.fullWidth ?? true;

  // const { t } = useTranslation();
  const form = props.form;
  const field = props.field;
  
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const onChange = getOnChangeSelectInput(props);



  return (
      <Grid container alignItems="center">
          
          <Grid item xs={12} md={5}>
              <StyledMuiFieldLabel label={props.label} paired={props.paired === true} />
          </Grid>

          <Grid item xs={12} md={7}>
              <Select
                  fullWidth={fullWidth}
                  id={field.name}
                  name={field.name}
                  type={props.type}
                  disabled={disabled}
                  value={field.value}
                  onChange={onChange}
                  onBlur={field.onBlur}
                  defaultChecked={field.checked}
                  error={touched && Boolean(errors)}
                  placeholder={props.helperText}

                  sx={{                        
                      backgroundColor: "white",
                      borderRadius: "14px",
                      [`& .${outlinedInputClasses.notchedOutline}`]: {
                          border: "1px solid #dfe0f2",
                          borderRadius: "14px",
                      },
                      
                      '&.Mui-focused':{
                          [`& .${outlinedInputClasses.notchedOutline}`]: {
                              border: "2px solid #1a0549"
                              
                          }
                      },
                      '&:hover':{
                          [`& .${outlinedInputClasses.notchedOutline}`]: {
                            border: "1px solid #dfe0f2",
                          }
                      },
                      '& .MuiOutlinedInput-input': {
                          color:"#250048",
                          padding:"5px 10px 5px 10px"
                      }
                  }}
              >
                  {props.children}
              </Select>
          </Grid>

      </Grid>
  );
}

export function StyledSelectSurface(props: IProps) : JSX.Element {
    
    const [antiGlare, setAntiGlare] = useState(props.field.value === Surface.AntiGlare)

    const disabled = props.disabled ?? false;
    const fullWidth = props.fullWidth ?? true;
  
    const form = props.form;
    const field = props.field;
    
    const errors = getIn(form.errors, field.name);
    const touched = getIn(form.touched, field.name);
  
    const onChange = (event: { target: { value: string } }) => {
        const target = event.target as HTMLInputElement
        target.name = props.field.name
        setAntiGlare(target.value === Surface.AntiGlare)
        applyRuleOnChange(props.form, {target, type: 'change'} as React.ChangeEvent<HTMLInputElement>)
    }
  
  
  
    return (<Box>
        <Grid container alignItems="center">
            
            <Grid item xs={12} md={5}>
                <StyledMuiFieldLabel label={props.label} paired={props.paired === true} />
            </Grid>
  
            <Grid item xs={12} md={7}>
                <Select
                    fullWidth={fullWidth}
                    id={field.name}
                    name={field.name}
                    type={props.type}
                    disabled={disabled}
                    value={field.value}
                    onChange={onChange}
                    onBlur={field.onBlur}
                    defaultChecked={field.checked}
                    error={touched && Boolean(errors)}
                    placeholder={props.helperText}

                    sx={{                        
                        backgroundColor: "white",
                        borderRadius: "14px",
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            border: "1px solid #dfe0f2",
                            borderRadius: "14px",
                        },
                        
                        '&.Mui-focused':{
                            [`& .${outlinedInputClasses.notchedOutline}`]: {
                                border: "2px solid #1a0549"
                                
                            }
                        },
                        '&:hover':{
                            [`& .${outlinedInputClasses.notchedOutline}`]: {
                              border: "1px solid #dfe0f2",
                            }
                        },
                        '& .MuiOutlinedInput-input': {
                            color:"#250048",
                            padding:"5px 10px 5px 10px"
                        }
                    }}
                >
                    {props.children}
                </Select>
            </Grid>
  
        </Grid>

        {antiGlare && <Box sx = {{ marginBlockStart: "10px"}}>
            <SolutionField name="customization.antiGlareType" />
            </Box>}
    </Box>);
  }

  export function StyledSelectThickness(props: IProps) : JSX.Element {

    const [other, setOther] = useState(props.field.value === -1)
    const disabled = props.disabled ?? false;
    const fullWidth = props.fullWidth ?? true;
  
    const form = props.form;
    const field = props.field;
    
    const errors = getIn(form.errors, field.name);
    const touched = getIn(form.touched, field.name);
  
    const onChange = (event: { target: { value: string } }) => {
        const target = event.target as HTMLInputElement
        target.name = props.field.name
        setOther(Number(target.value) === -1)
        console.log(target.value, props.field.value)
        applyRuleOnChange(props.form, {target, type: 'change'} as React.ChangeEvent<HTMLInputElement>)
    }
  
  
  
    return (<Box>
        <Grid container alignItems="center">
            
            <Grid item xs={12} md={5}>
                <StyledMuiFieldLabel label={props.label} paired={props.paired === true} />
            </Grid>
  
            <Grid item xs={12} md={7}>
                <Select
                    fullWidth={fullWidth}
                    id={field.name}
                    name={field.name}
                    type={props.type}
                    disabled={disabled}
                    value={field.value}
                    onChange={onChange}
                    onBlur={field.onBlur}
                    defaultChecked={field.checked}
                    error={touched && Boolean(errors)}
                    placeholder={props.helperText}

                    sx={{                        
                        backgroundColor: "white",
                        borderRadius: "14px",
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            border: "1px solid #dfe0f2",
                            borderRadius: "14px",
                        },
                        
                        '&.Mui-focused':{
                            [`& .${outlinedInputClasses.notchedOutline}`]: {
                                border: "2px solid #1a0549"
                                
                            }
                        },
                        '&:hover':{
                            [`& .${outlinedInputClasses.notchedOutline}`]: {
                              border: "1px solid #dfe0f2",
                            }
                        },
                        '& .MuiOutlinedInput-input': {
                            color:"#250048",
                            padding:"5px 10px 5px 10px"
                        }
                    }}
                >
                    {props.children}
                </Select>
            </Grid>
  
        </Grid>

        {other && props.form.values.product.type === ProductType.FortouchO && (
            <Box sx={{paddingTop: "10px"}} >
            <PairedInput bigLabel="Custom thickness (mm)" elements={[
                <SolutionField name="glass.coverGlass[0]" />,
                <SolutionField name="glass.coverGlass[1]" />,
                <SolutionField name="glass.coverGlass[2]" />
            ]}/> </Box>
        )}

        {other && props.form.values.product.type !== ProductType.FortouchO && (
            <Box sx={{paddingTop: "10px"}} >
            <PairedInput bigLabel="Custom thickness (mm)" elements={[
                <SolutionField name="glass.coverGlass[0]" />
            ]}/> </Box>
        )}
    </Box>);
  }

export function StyledItemMuiFiled(props : IItemProps) : JSX.Element {
  
  /* Used in SolutionField.tsx */
  return (
      <MenuItem
          key={props.value}
          value={props.value}
          sx={{
              color: "#250048",
          }}>
          {props.valToStr ? props.valToStr(props.value) : props.value}
      </MenuItem>
  )
}