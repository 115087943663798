import { LocationDirection, Side, floatToStr } from "../models/Common";
import { ISolution } from "../models/Solution";
import { ActiveArea } from "./ActiveArea";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";

export class Sensor extends BaseComponent {
    public constructor(id: number, solution: ISolution, innerArea: IComponentShape, activeArea: ActiveArea) {
        const sensor = solution.sensor;
        const diagonal = floatToStr(sensor.diagonal);


        const sensorWidth = sensor.activeArea.width + sensor.passiveArea.l + sensor.passiveArea.r
        const sensorHeight = sensor.activeArea.height + sensor.passiveArea.t + sensor.passiveArea.b

        super({
            id,
            color: "#00ccff",
            description: `touch sensor ${diagonal} inch`,
        } as IComponentInfo,
        {
            width: sensorWidth,
            height: sensorHeight,
            centralOffsetX: (activeArea.shape.centralOffsetX ?? 0) + (sensor.passiveArea.r - sensor.passiveArea.l)/2,
            centralOffsetY: (activeArea.shape.centralOffsetY ?? 0) + (sensor.passiveArea.b - sensor.passiveArea.t)/2,
        } as IComponentShape,
        {
            dimensions: {
                sides: [ Side.Bottom, Side.Right ],
                diff: true,
            },
            description: false,
            id: {
                locationDirection: LocationDirection.Inward,
                fromSide: Side.Right,
                referenceShape: innerArea,
                padding: 20,
                perpendicularOffset: -25,
            },
        } as IComponentLabels);
    }
}