import { Side } from "../models/Common";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";
import { ExtraLayersProfile } from "./ExtraLayersProfile";
import { GlassProfile } from "./GlassProfile";
import { SensorProfile } from "./SensorProfile";

export class ProfileThickness extends BaseComponent {

  public constructor(glass: GlassProfile, extra: ExtraLayersProfile | undefined, sensor: SensorProfile | undefined, typeO: boolean) {

    const height = glass.shape.height + 
      (sensor ? sensor.shape.height : 0) + 
      (extra  ? typeO ? 0 : extra?.shape.height : 0)
    
    const offsetY = (glass.shape.centralOffsetY || 0) +
      (extra  ? typeO ? 0 : -(extra.shape.centralOffsetY || 0)/2 : 0) +
      (sensor ? -(sensor.shape.centralOffsetY || 0)/2 : 0)

    super({
      id: -1,
      color: "#000",
      description: ""
    }as IComponentInfo,
    {
      width: 0,
      height: height,
      centralOffsetX: glass.shape.centralOffsetX,
      centralOffsetY: offsetY
    } as IComponentShape,
    {
      dimensions: {
        sides: [Side.Right],
        diff: false,
      },
      description: false,
    } as IComponentLabels)
  }

  override render() {
    return <></>
  }
}