import { ISensor } from "./Sensor";
import { IProduct, ProductType, ProductUsage } from "./Product";
import { IGlass, otherThickness } from "./Glass";
import { IGlassCustomization, doubleSurfaces, surfaces } from "./Customization";
import { ICustomer } from "./Customer";
import { IStatus } from "./Status";
import { IPrinting } from "./Printing";


export interface ISolution {
    product: IProduct,
    glass: IGlass,
    customization: IGlassCustomization,
    printing: IPrinting,
    sensor: ISensor,
    customer: ICustomer,
    status: IStatus,
}

export interface IRange<T> {
    min?: T, // including
    max?: T, // including
}
export type INumericRange = IRange<number>;

export function isRange(value: any): value is IRange<any> {
    return "min" in value || "max" in value;
}

export function isNumericRange(value: any): value is INumericRange {
    return isRange(value) && typeof value.min === 'number' && typeof value.max === 'number';
}

/**
 * Defines constraints for all attributes of an object of type T. 
 * An attribute can either be either numeric or value-based (e.g., enum).
 * Numeric attributes can be constrained to a range of [min, max] (incl.).
 * Value-based attributes can be constrained to a specific set of values.
 * Constraining each attribute is optional.
 */
type IConstraints<T> = {
    [Attribute in keyof T]?: T[Attribute] extends number ? INumericRange : T[Attribute][];
}
/**
 * Defines constraints for each top-level attribute of ISolution.
 * Constraining each attribute is optional.
 */
export type ISolutionConstraints = {
    [Attribute in keyof ISolution]?: IConstraints<ISolution[Attribute]>;
}

export const defaultConstraints = {
    product: {
        pieces: { min: 1 },
    },
    glass: {
        width: { min: 1 },
        height: { min: 1 },
    },
    sensor: {
        diagonal: { min: 1 },
    },
} as ISolutionConstraints;


export const FortouchOFixedValues = {
    glass: {
        isDouble: true,
    },
    customization: {
        otherSettings: {
            PF: false
        }
    },
} as Partial<ISolution>;

export const FortouchOConstraints = {
    ...defaultConstraints,
    product: {
        usage: [ProductUsage.Outdoor, ProductUsage.Indoor]
    },
    glass: {
        thickness: [4.3, 5.3, 6.3, 7.3, 8.3, otherThickness],
    },
    customization: {
        surface: doubleSurfaces,
    },
    sensor: {
        diagonal: { min: 7, max: 110 },
    },
} as ISolutionConstraints;


export const FortouchSFixedValues = {
    glass: {
        isDouble: false,
    },
    customization: {
    },
} as Partial<ISolution>;

export const FortouchSConstraints = {
    ...defaultConstraints,
    product: {
        usage: [ProductUsage.Indoor, ProductUsage.Outdoor]
    },
    glass: {
        thickness: [ 1, 2, 3, 4, 5, 6, otherThickness],
    },
    customization: {
        surface: surfaces,
    },
    sensor: {
        diagonal: { min: 7, max: 86 },
    },
} as ISolutionConstraints;


export const FortouchCFixedValues = {
    glass: {
        isDouble: false,
    },
    customization: {
    }
} as Partial<ISolution>;

export const FortouchCConstraints = {
    ...defaultConstraints,
    product: {
        usage: [ ProductUsage.Indoor, ProductUsage.Outdoor, ProductUsage.SemiOutdoor ],
    },
    glass: {
        thickness: [1, 2, 3, 4, 5, 6, 7, 8, otherThickness],
    },
    customization: {
        surface: surfaces,
    },
    sensor: {
        diagonal: { min: 7, max: 110 },
    },
} as ISolutionConstraints;

export const CoverGlassFixedValues = {
    glass: {
        withGlass: true,
        isDouble: false
    },
    sensor: {
        withSensor: false
    }
} as Partial<ISolution>

export const CoverGlassConstraints = {
    glass: {
        thickness: [1, 2, 3, 4, 5, 6, 7, 8, otherThickness]
    }
} as ISolutionConstraints




export interface ISolutionConfig {
    fixedValues: Partial<ISolution>,
    constraints: ISolutionConstraints,
}

export function getSolutionConfig(productType: ProductType): ISolutionConfig {
    switch (productType) {
        case ProductType.FortouchO:
            return { fixedValues: FortouchOFixedValues, constraints: FortouchOConstraints };
        case ProductType.FortouchS:
            return { fixedValues: FortouchSFixedValues, constraints: FortouchSConstraints };
        case ProductType.FortouchC:
            return { fixedValues: FortouchCFixedValues, constraints: FortouchCConstraints };
    }
}
