import * as d3 from "d3";
import { createContext } from "react";
import { ISidePadding, ISideOffsets, identity, Orientation } from "../models/Common";
import { getBounds } from "../models/Rectangle";
import { BaseComponent } from "../components/Base";

export interface IScaleContext {
    size: d3.ScaleLinear<number, number, never>,
    posX: d3.ScaleLinear<number, number, never>,
    posY: d3.ScaleLinear<number, number, never>,
}
export const ScaleContext = createContext(createDefaultScale() as IScaleContext);

export function createDefaultScale(): IScaleContext {
    return {
        size: d3.scaleLinear(),
        posX: d3.scaleLinear(),
        posY: d3.scaleLinear(),
    };
}

export const identityScale = {
    size: identity,
    posX: identity,
    posY: identity,
} as IScaleContext;

export interface IColorContext {
    color: string,
}
export const ColorContext = createContext({ color: "currentColor" } as IColorContext);

export interface IFontContext {
    size: number, // canvas px
}
export const FontContext = createContext({ size: 14 } as IFontContext);

export interface IArea {
    width: number,
    height: number,
    offsetX: number, // applied to top-left corner
    offsetY: number, // applied to top-left corner
}


export function getDrawingInfo(components: BaseComponent[], canvasArea: IArea, canvasPadding: ISidePadding, canvasInnerOffsets: ISideOffsets) {
    const componentBoundsIndiv = components.map(c => getBounds(c.shape));

    const componentBoundsMm = {
      _x: componentBoundsIndiv.reduce((min, cb) => Math.min(min, cb._x), 0),
      x: componentBoundsIndiv.reduce((max, cb) => Math.max(max, cb.x), 0),
      _y: componentBoundsIndiv.reduce((min, cb) => Math.min(min, cb._y), 0),
      y: componentBoundsIndiv.reduce((max, cb) => Math.max(max, cb.y), 0)
    };
    
    const drawingBoundsMm = {...componentBoundsMm};

    let drawingWmm = drawingBoundsMm.x - drawingBoundsMm._x;
    let drawingHmm = drawingBoundsMm.y - drawingBoundsMm._y;


    const drawingWcanvas = canvasArea.width - canvasPadding.l - canvasPadding.r + canvasInnerOffsets.l - canvasInnerOffsets.r;
    const drawingHcanvas = canvasArea.height  - canvasPadding.t - canvasPadding.b + canvasInnerOffsets.t - canvasInnerOffsets.b;
    const drawingCanvasAspect = drawingWcanvas / drawingHcanvas;


    if (drawingWmm / drawingHmm < drawingCanvasAspect) {
        const newDrawingWmm = drawingHmm * drawingCanvasAspect;
        const diffX = (newDrawingWmm - drawingWmm) / 2;
        drawingWmm = newDrawingWmm;
        drawingBoundsMm.x += diffX;
        drawingBoundsMm._x -= diffX;
    }
    else {
        const newDrawingHmm = drawingWmm / drawingCanvasAspect;
        const diffY = (newDrawingHmm - drawingHmm) / 2;
        drawingHmm = newDrawingHmm;
        drawingBoundsMm.y += diffY;
        drawingBoundsMm._y -= diffY;
    }

    const scale = createDefaultScale();

    scale.size = d3.scaleLinear().domain([0, 100 * Math.max(drawingWmm, drawingHmm)]).range([0, 100 * Math.max(drawingWcanvas, drawingHcanvas)]);
 
    const canvasXPosExtent = [canvasArea.offsetX + canvasPadding.l, canvasArea.offsetX + canvasArea.width - canvasPadding.r];
    const canvasYPosExtent = [canvasArea.offsetY + canvasPadding.t, canvasArea.offsetY + canvasArea.height - canvasPadding.b];
    const mmXPosExtent = [drawingBoundsMm._x - scale.size.invert(-canvasInnerOffsets.l), 1*(drawingBoundsMm.x + scale.size.invert(canvasInnerOffsets.r))];
    const mmYPosExtent = [drawingBoundsMm._y - scale.size.invert(-canvasInnerOffsets.t), 1*(drawingBoundsMm.y + scale.size.invert(canvasInnerOffsets.b))];

    scale.posX = d3.scaleLinear().domain(mmXPosExtent).range(canvasXPosExtent);
    //scale.posY = d3.scaleLinear().domain(mmXPosExtent).range(canvasXPosExtent);
    scale.posY = d3.scaleLinear().domain(mmYPosExtent).range(canvasYPosExtent);

    return { scale, drawingBounds: drawingBoundsMm, componentBounds: componentBoundsMm, drawingOrientation: Orientation.Landscape };
}

export function getInfo(drawingScale: IScaleContext, canvasArea: IArea, canvasPadding: ISidePadding) {
    
    const scale = createDefaultScale();
    scale.size = drawingScale.size;


  
    const drawingWcanvas = canvasArea.width - canvasPadding.l - canvasPadding.r;
    const drawingHcanvas = canvasArea.height  - canvasPadding.t - canvasPadding.b;

    const drawingBoundsMm = {
        _x: -0.5 * scale.size.invert(drawingWcanvas),
        x: 0.5 * scale.size.invert(drawingWcanvas),
        _y: -0.5 * scale.size.invert(drawingHcanvas),
        y: 0.5 * scale.size.invert(drawingHcanvas)
      };

    const canvasXPosExtent = [canvasArea.offsetX + canvasPadding.l, canvasArea.offsetX + canvasArea.width - canvasPadding.r];
    const canvasYPosExtent = [canvasArea.offsetY + canvasPadding.t, canvasArea.offsetY + canvasArea.height - canvasPadding.b];
    const mmXPosExtent = [drawingBoundsMm._x, drawingBoundsMm.x];
    const mmYPosExtent = [drawingBoundsMm._y, drawingBoundsMm.y];

    scale.posX = d3.scaleLinear().domain(mmXPosExtent).range(canvasXPosExtent);
    //scale.posX = drawingScale.posX
    scale.posY = d3.scaleLinear().domain(mmYPosExtent).range(canvasYPosExtent);
    //scale.posY = d3.scaleLinear().domain(mmXPosExtent).range(canvasXPosExtent);

    return { scale };
    //return { scale, drawingBounds: drawingBoundsMm };
}
