import { Fragment, useMemo } from "react";
import { IArea } from "./Common";
import React from "react";

export interface ITextBlockProps {
    area: IArea,
    paragraphs: string[],
    color?: string,
    colors?: string[],
}

//Super ineffective
function fitsText(area : IArea, paragraphs : string[], lineHeight : number, paragraphGap : number, avgCharWidth : number, charsPerLine : number) : Boolean {

        let spans: string[][] = [];

        for (let i = 0; i < paragraphs.length; ++i) {
            const words = paragraphs[i].split(/\s+/).reverse();
            let word;
            let lines : any[] = [];
            let line = '';
            
            while (words.length > 0) {
                word = words.pop();
                if (!word) break; // won't happen

                if (word.length <= charsPerLine && line.length + word.length + 1 > charsPerLine) {
                    lines.push(line);
                    line = '';
                }
                
                if (word.length > charsPerLine) {
                    const c = charsPerLine - line.length - 1;
                    const w1 = word.slice(0, c) + '-';
                    const w2 = word.slice(c);
                    
                    words.push(w2);
                    word = w1;
                }
                
                line += ' ' + word;
            }
            lines.push(line);
            spans.push(lines);
        }
    const textSpans = spans



    let y = area.offsetY + lineHeight;
    const ys = textSpans.map(c => {
        const prevDy = y;
        y += c.length * lineHeight + paragraphGap;
        return prevDy;
    });

    return (ys[ys.length - 1] + lineHeight) - ys[0] <= area.height
}

export function TextBlock({ area, paragraphs, color, colors }: ITextBlockProps) {
    let fontSize = 15

    color = color ?? "#000";
    let lineHeight = 1.25 * fontSize;
    let paragraphGap = 0.5 * lineHeight;
    let avgCharWidth = 0.6 * fontSize;
    let charsPerLine = area.width / avgCharWidth;
    
    do {
        fontSize -= 1
        lineHeight = 1.25 * fontSize;
        paragraphGap = 0.5 * lineHeight;
        avgCharWidth = 0.6 * fontSize;
        charsPerLine = area.width / avgCharWidth;
    } while (!fitsText(area, paragraphs, lineHeight, paragraphGap, avgCharWidth, charsPerLine))
    // Do approximate word-wrapping based on expected character width:
    const textSpans = useMemo(() => {
        let spans: string[][] = [];
        
        for (let i = 0; i < paragraphs.length; ++i) {
            const words = paragraphs[i].split(/\s+/).reverse();
            let word;
            let lines : any[] = [];
            let line = '';
            
            while (words.length > 0) {
                word = words.pop();
                if (!word) break; // won't happen

                if (word.length <= charsPerLine && line.length + word.length + 1 > charsPerLine) {
                    lines.push(line);
                    line = '';
                }
                
                if (word.length > charsPerLine) {
                    const c = charsPerLine - line.length - 1;
                    const w1 = word.slice(0, c) + '-';
                    const w2 = word.slice(c);
                    
                    words.push(w2);
                    word = w1;
                }
                
                line += ' ' + word;
            }
            lines.push(line);
            spans.push(lines);
        }
        return spans;
    }, [paragraphs, charsPerLine]);

    const ys = useMemo(() => {
        let y = area.offsetY + lineHeight;
        const ys = textSpans.map(c => {
            const prevDy = y;
            y += c.length * lineHeight + paragraphGap;
            return prevDy;
        });
        return ys;
    }, [textSpans, area, paragraphGap, lineHeight]);

    return <g>
        {paragraphs.map((p, pi) => <Fragment key={pi}>
            {textSpans[pi].map((s, si) => 
                <text x={area.offsetX} y={ys[pi] + si * lineHeight} key={si} textAnchor="left" fontSize={fontSize} fill={colors ? colors[pi] : color}>{s}</text>)}
        </Fragment>)}
    </g>;
}