import { ISideOffsets, LocationDirection, Side } from "../models/Common";
import { IGlass } from "../models/Glass";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";

export class PrintedBorder extends BaseComponent {
    public constructor(id: number, color: string, glass: IGlass, doublePrint: boolean, frameThickness: ISideOffsets) {

        super({
            id,
            color: "#6ced48",
            description: (`printed border with color ${color}${doublePrint ? `, tow layers of printing` : ''}`), //on the rear face of the glass`,
        } as IComponentInfo,
        {
            width: glass.width - frameThickness.r - frameThickness.l,
            height: glass.height - frameThickness.b - frameThickness.t,
            centralOffsetX: 0.5 * (frameThickness.l - frameThickness.r),
            centralOffsetY: 0.5 * (frameThickness.t - frameThickness.b),
        } as IComponentShape,
        {
            dimensions: {
                sides: [ Side.Top, Side.Left ],
                diff: true,
            },
            description: false,
            id: {
                locationDirection: LocationDirection.Inward,
                fromSide: Side.Right,
                padding: 20,
                perpendicularOffset: 25,
            },
        } as IComponentLabels);
    }
}