import { IProps } from "../MuiField";
import { getOnChange } from "../../models/Form";
import { Grid, TextField } from "@mui/material";
import { StyledMuiFieldLabel } from "./MuiFIeldLabel";


interface IRawTextMuiField {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    name: string,
    defaultValue: string,
    disabled?: boolean
    helperText?: string,
    required? : boolean,
    email?: boolean
}


export function StyledTextMuiField(props: IProps) : JSX.Element {

  const disabled = props.disabled ?? false;
  let xsmd;
  if(props.xsmd === undefined || props.xsmd.length !== 2) {
    xsmd = [5,7]
  } else {
    xsmd = props.xsmd
  }

  const field = props.field;
  const onChange = getOnChange(props);

  return (
  <Grid container alignItems="center">
      <Grid item xs={12} md={xsmd[0]}>
          <StyledMuiFieldLabel label={props.label} paired={props.paired === true}/>

      </Grid>
      <Grid item xs={12} md={xsmd[1]}>
            <RawTextMuiField name={field.name} onChange={onChange} helperText={props.helperText} defaultValue={field.value} disabled={disabled} required={props.required} email={props.email}/> 
      </Grid>
  </Grid>
  )


}


export function RawTextMuiField(props : IRawTextMuiField) : JSX.Element {
    const pattern = props.email? ".+[@].+[.].+" : ".+"
    return (
        <TextField
            id={props.name}
            name={props.name}
            disabled={props.disabled}
            value={props.defaultValue}
            onChange={props.onChange}
            placeholder={props.helperText}
            required = {props.required}
            inputProps={{pattern: pattern}}
            sx={{
                backgroundColor: "white",
                border: "1px solid #dfe0f2",
                borderRadius: "14px",
                maxHeight:"33px",
                '& .MuiOutlinedInput-notchedOutline': {
                    border: "0px solid black",
                },
                '& .MuiOutlinedInput-root': {
                    color: "#250048",
                    padding:"0px",
                    borderRadius: "14px",
                    '&.Mui-focused fieldset': {
                    borderColor: '#1a0549',
                    },
                },
                '& .MuiOutlinedInput-input': {
                    padding:"5px 10px 5px 10px"
                },            
                '& .Mui-error': {
                    border: "1px solid red",
                },
                input: {
                    '&:invalid': {
                        borderRadius: "14px",
                        border: "1px solid red"
                    }
                }
            }}
        />    
    )
}
