export enum ProductType {
    FortouchO = "FORTOUCH O",
    FortouchS = "FORTOUCH S",
    FortouchC = "FORTOUCH C"
}
export const productTypes = [ ProductType.FortouchO, ProductType.FortouchC, ProductType.FortouchS ];

export enum ProductUsage {
    Outdoor = "Outdoor", Indoor = "Indoor", SemiOutdoor = "Semi-outdoor"
}
export const productUsages = [ ProductUsage.Indoor, ProductUsage.Outdoor, ProductUsage.SemiOutdoor ];

export interface IProduct {
    type: ProductType,
    usage: ProductUsage,
    pieces: number,
    notes: string,
    title: string,
    drawingNumber: string
}
