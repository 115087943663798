import React from "react";
import { RoundedRect } from "../drawing/Rectangle";
import { LocationDirection, Side } from "../models/Common";
import { ImgRectangle } from "../drawing/ImgRectangle";

export interface IComponentInfo {
    id: number,
    color: string,
    description: string,
    img: any
}

export interface IComponentShape {
    width: number,
    height: number,
    centralOffsetX?: number,
    centralOffsetY?: number,
}

export interface IComponentLabels {
    dimensions?: {
        sides: Side[],
        diff: boolean,
    },
    description: boolean,
    id?: {
        locationDirection: LocationDirection,
        fromSide: Side,
        referenceShape: IComponentShape,
        perpendicularOffset?: number,
        padding?: number,
    }
}

export class BaseComponent {
    public constructor(
        public info: IComponentInfo,
        public shape: IComponentShape,
        public labels: IComponentLabels)
    {
    }

    render(canvasW : number) {
        if(this.info.img) {
            
            return <ImgRectangle key={this.info.id} shape={this.shape} file={this.info.img} canvasW={canvasW}/>
        }else {
            
            return <RoundedRect key={this.info.id} shape={this.shape} info={this.info}/>
        }
    }
}