import { IArea } from "./Common";

export function ProductNotes(props: {
  area: IArea,
  text: string,
  fill: string,
  fontSize: number
}) : JSX.Element {
  const text: Array<string> = []
  const maxChar = Math.round(props.area.width / props.fontSize * 1.5)
  let cluster: Array<string> = []
  let breakCluster: boolean = false
  let i = 0;
  props.text.split('').forEach((char) => {
    console.log(char, i)
    if(i > maxChar) {
      breakCluster = true
    }
    if(breakCluster && char === " ") {
      breakCluster = false
      text.push(cluster.join(""))
      cluster = []
      i = 0
    }
    cluster.push(char)
    i++
  })

  console.log(text)
  return <>{text.map((str: string, i: number) =>
    <text 
      x={props.area.offsetX} y={props.area.offsetY + i * 1.5 * props.fontSize}
      fontSize={props.fontSize} fill={props.fill}
    >
      {str}
    </text>
  )}</>
}