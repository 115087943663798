import { IRadius, parseEnum } from "./Common";

export enum Surface {
    AntiGlare = "Anti-glare", AntiReflective = "Anti-reflective", UltraClear = "Ultra clear"
}
export const surfaces = [ Surface.AntiGlare, Surface.AntiReflective, Surface.UltraClear ];
export const doubleSurfaces = [
    Surface.AntiGlare,
    Surface.AntiReflective,
    Surface.UltraClear,
    Surface.AntiReflective,
] as Surface[];

export enum AntiGlareType {
    AG90 = "90", AG110 = "110"
}
export const antiGlareTypes = [ AntiGlareType.AG90, AntiGlareType.AG110 ];

export interface IGlassCustomization {
    corners: IRadius, // mm
    surface: Surface,
    antiGlareType: AntiGlareType | null,
    cutouts: number,
    holes: number,
    otherSettings: {
        UV: boolean,
        IR: boolean,
        PF: boolean
    },
    extraLayers: number //mm
}

export function areAllCornersSame(corners?: IRadius) {
    if (!corners)
        return true;
    return (corners.tl === corners.tr) && (corners.tr === corners.br) && (corners.br === corners.bl);
}

export function surfaceToStr(material: IGlassCustomization['surface']) {
    if (!Array.isArray(material))
        return material.toString();

    return material[0].toString() + " + " + material[1].toString(); 
}

export function parseSurface(value: any) {
    if (typeof value === 'string') {
        const separator = ' + ';
        if (!value.includes(separator))
            return parseEnum(Surface, value);

        const parts = value.split(separator);
        return [ parseEnum(Surface, parts[0]), parseEnum(Surface, parts[1]) ];
    }
    return value;
}

export function hasAntiGlare(material: IGlassCustomization['surface']) {
    if (Array.isArray(material))
        return material.includes(Surface.AntiGlare);
    return material === Surface.AntiGlare;
}