import React from "react";
import { RoundedRect } from "../drawing/Rectangle";
import { LocationDirection, Side } from "../models/Common";
import { ISolution } from "../models/Solution";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";
import { Glass } from "./Glass";
import { ProductType } from "../models/Product";

export class GlassProfile extends BaseComponent {
    private parts?: IComponentShape[];
    public dif?: number

    public constructor(solution: ISolution, glass: Glass, centralOffsetX: number) {
        const glassThickness = solution.glass.thickness;

        const overallThickness = glassThickness !== -1 ?
            glassThickness
            :
            solution.product.type !== ProductType.FortouchO ?
                solution.glass.coverGlass[0]
                :
                solution.glass.coverGlass.reduce((sum: number, current: number) => sum + current, 0)

        const description = glassThickness !== -1 ?
            ""
            :
            solution.product.type !== ProductType.FortouchO ?
                `Custom glass ${solution.glass.coverGlass[0]} mm`
                :
                `Custom 1. coverglass ${solution.glass.coverGlass[0]} mm, 2. coverglass ${solution.glass.coverGlass[1]} mm, 3. coverglass ${solution.glass.coverGlass[2]} mm`

        super({
            id: glass.info.id,
            color: "#000",
            description: description
        } as IComponentInfo,
        {
            width: glass.shape.width,
            height: overallThickness,
            centralOffsetX: centralOffsetX,
            centralOffsetY: 0
        } as IComponentShape, // overall shape
        {
            dimensions: {
                sides: [ Side.Right ],
                diff: false,
            },
            id: {
                locationDirection: LocationDirection.Outward,
                fromSide: Side.Bottom,
                padding: 10,
            },
            description: false,
        } as IComponentLabels);

      
        if (solution.glass.isDouble) {
            if (glassThickness !== -1)
                //return `${Math.ceil((thickness-0.3)/2)}+${Math.floor((thickness-0.3)/2)}`
                this.parts = [
                    {
                        width: this.shape.width,
                        height: Math.ceil((glassThickness-0.3)/2),
                        centralOffsetX: this.shape.centralOffsetX,
                        centralOffsetY: -Math.ceil((glassThickness-0.3)/2)/2
                    },
                    {
                        width: this.shape.width,
                        height: Math.floor((glassThickness-0.3)/2),
                        centralOffsetX: this.shape.centralOffsetX,
                        centralOffsetY: Math.ceil((glassThickness-0.3)/2)/2,
                    },
                ]
            else {
                this.parts = [
                    {
                        width: this.shape.width,
                        height: solution.glass.coverGlass[0],
                        centralOffsetX: this.shape.centralOffsetX,
                        centralOffsetY: -(solution.glass.coverGlass[0] + solution.glass.coverGlass[1])/2
                    },
                    {
                        width: this.shape.width,
                        height: solution.glass.coverGlass[1],
                        centralOffsetX: this.shape.centralOffsetX,
                        centralOffsetY: 0
                    },
                    {
                        width: this.shape.width,
                        height: solution.glass.coverGlass[2],
                        centralOffsetX: this.shape.centralOffsetX,
                        centralOffsetY: (solution.glass.coverGlass[2] + solution.glass.coverGlass[1])/2
                    }
                ]
                this.dif = solution.glass.coverGlass[0] - solution.glass.coverGlass[2]
                this.parts.forEach(shape => {shape.centralOffsetY = shape.centralOffsetY! + this.dif!/2})
            }
        }
    }

    override render() {
        return <>
            {!this.parts && <RoundedRect shape={this.shape} info={this.info} />}
            {this.parts && this.parts.map((shape, i) =>
                <RoundedRect key={i} shape={shape} stroke={0.3} info={this.info} />
            )}
        </>;
    }
}