import { LocationDirection, Side } from "../models/Common";
import { TailAttachment, horizontalAttachments } from "../models/Sensor";
import { ISolution } from "../models/Solution";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";
import { Sticker } from "./Sticker";

export class FlexibleCable extends BaseComponent {
    public constructor(id: number, solution: ISolution, sticker: Sticker ) {


        const flexWidth = solution.sensor.tail.width;
        const flexHeight = solution.sensor.cable.height;

        const tail = solution.sensor.tail;
        const tailRotated = tail.attachment === TailAttachment.Left || tail.attachment === TailAttachment.Right;
        
        const labelSide = horizontalAttachments.includes(tail.attachment) ? Side.Top : Side.Right;

        let fcox = sticker.shape.centralOffsetX ?? 0;
        let fcoy = sticker.shape.centralOffsetY ?? 0;

        switch (solution.sensor.tail.attachment) {
            case TailAttachment.Top:
                fcoy += -0.5 * tail.length - 0.5 * flexHeight;
                break;
            case TailAttachment.Bottom:
                fcoy += 0.5 * tail.length + 0.5 * flexHeight;
                break;
            case TailAttachment.Left:
                fcox += -0.5 * tail.length - 0.5 * flexHeight;
                break;
            case TailAttachment.Right:
                fcox += 0.5 * tail.length + 0.5 * flexHeight;
                break;
        }

        super({
            id,
            color: "#000",
            description: "flexible cable",
        } as IComponentInfo,
        {
            width: tailRotated ? flexHeight : flexWidth,
            height: tailRotated ? flexWidth : flexHeight,
            centralOffsetX: fcox,
            centralOffsetY: fcoy,
        } as IComponentShape,
        {
            dimensions: {
                sides: [Side.Top, Side.Right],//sides: [ dimensionSide ],
                diff: true,
            },
            description: false,
            id: {
                locationDirection: LocationDirection.Outward,
                fromSide: labelSide,
                padding: 10,
            },
        } as IComponentLabels);
    }
}