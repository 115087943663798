import React from 'react';
import { FieldProps } from "formik";
import { getOnChange } from '../models/Form';





export default function FileDialog(props: FieldProps & { label: string, disabled?: boolean, fileArr: [], indexOfObj: number}) {



  const handleChange = getOnChange(props, props.fileArr, props.indexOfObj)






  return (
    <div>
      <input type="file" onChange={(e) => handleChange(e)} name={props.field.name}/>
    </div>
  );
};

