import { IDimensions2D, Orientation } from "./Common";

export type otherThicknessType = -1;
export const otherThickness = -1;

export function thicknessToString(glass: IGlass, thickness: IGlass['thickness']) : string{
    if (thickness === otherThickness)
        return "Other";

    if (glass.isDouble)
        return `${Math.ceil((thickness-0.3)/2)}+${Math.floor((thickness-0.3)/2)}`;

    return thickness.toString();
}

export interface IGlass extends IDimensions2D {
    withGlass: boolean,
    thickness: number | otherThicknessType, // mm
    coverGlass: [number, number, number]
    isDouble: boolean,
    orientation: Orientation,
}
