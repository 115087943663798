import { useContext, useMemo } from "react";
import { ScaleContext } from "./Common";
import * as d3 from "d3";
import { IRoundedRectShape, getCorners, getLinePoints, getRadius } from "../models/Rectangle";
import { IComponentInfo } from "../components/Base";
import React from "react";


export interface IRoundedRectProps {
  shape: IRoundedRectShape,
  info: IComponentInfo,
  stroke?: number
}

export function RoundedRect({ shape, info, stroke }: IRoundedRectProps) {
  const scale = useContext(ScaleContext);
  const radius = useMemo(() => getRadius(shape, scale), [shape, scale]);
  const points = useMemo(() => getLinePoints(shape, scale), [shape, scale]);
  const corners = useMemo(() => getCorners(shape, scale), [shape, scale]);

  const path = useMemo(() => {
    const p = d3.path();
    
    
    
    p.moveTo(...points[0])
    p.lineTo(...points[1]);
    p.arcTo(...corners[1], ...points[2], radius.tr);

    p.lineTo(...points[3]);
    p.arcTo(...corners[2], ...points[4], radius.br);

    p.lineTo(...points[5]);
    p.arcTo(...corners[3], ...points[6], radius.bl);

    p.lineTo(...points[7]);
    p.arcTo(...corners[0], ...points[0], radius.tl);
     
    p.closePath();
    return p;
  }, [corners, points, radius]);

  return <path fill="none" stroke={info.color ?? "currentColor"} strokeWidth={`${stroke? stroke: 1}`} d={path.toString()} />
}