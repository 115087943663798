import { AdditionalNotesTypesEnum, IAdditionalNotes } from "../models/AdditionalText";
import { ISideOffsets } from "../models/Common";
import { IGlassCustomization } from "../models/Customization";
import { IGlass } from "../models/Glass";
import { IPrintedObject } from "../models/Printing";
import { isLargerThan } from "../models/Rectangle";
import { ISensor, ITail, TailAttachment } from "../models/Sensor";
import { ISolution } from "../models/Solution";
import { ISolutionField } from "../models/SolutionFields";
import { ActiveArea } from "./ActiveArea";
import { Glass } from "./Glass";
import { PrintedBorder } from "./PrintedBorder";
import { Sensor } from "./Sensor";


/////TODDO FIX might not be correct pushing



export function objectNotesAdditional(objects : IPrintedObject[] | undefined, withLogo? : boolean) : IAdditionalNotes[] {
    const ret: IAdditionalNotes[] = []
    if(!objects) return ret
    if(withLogo !== true) return ret
    if(objects.length <= 0) return ret

    const titledObjects : IPrintedObject[] = objects.filter(obj => obj.title)
    const untitledObject : IPrintedObject[] = objects.filter(obj => !obj.title)
    const untitledHoleObject : IPrintedObject[] = untitledObject.filter(obj => obj.isHole)
    const untitledTransparentObject : IPrintedObject[] = untitledObject.filter(obj => obj.isTransparent)
    const untitledNoneObject : IPrintedObject[] = untitledObject.filter(obj => !(obj.isHole || obj.isTransparent))

    let iStr = "NOTE: "
    titledObjects.forEach((titledObject, i) => {
        if (titledObject.isTransparent) iStr += "trasnparent "
        if (titledObject.isHole) iStr += "hole "
        iStr += `object ${i + 1}: "${titledObject.title}" \n`
    })
    ret.push({text: iStr, type: AdditionalNotesTypesEnum.Note})

    if (untitledHoleObject.length > 0) ret.push({text: `NOTE: ${untitledHoleObject.length} untitled hole objects\n`, type: AdditionalNotesTypesEnum.Note})
    if (untitledTransparentObject.length > 0) ret.push({text: `NOTE: ${untitledTransparentObject.length} untitled transparent objects\n`, type: AdditionalNotesTypesEnum.Note})
    if (untitledNoneObject.length > 0) ret.push({text: `NOTE: ${untitledNoneObject.length} untitled objects\n`, type: AdditionalNotesTypesEnum.Note})

    const colors = objects.flatMap(object => object.colors).map(color => color.trim());
    const dedupColors = Array.from(new Set(colors));
    ret.push({text: `NOTE: used ${dedupColors.length} colors (total)`, type: AdditionalNotesTypesEnum.Note})
    return ret

}

export function objectErrorsAdditional(objects : IPrintedObject[] | undefined, glass : IGlass, borderSol: ISideOffsets | undefined, withLogo? : boolean) : IAdditionalNotes[] {
    const ret : IAdditionalNotes[] = []
    if(withLogo !== true) return ret
    
    if(objects) {
        const errObjects : IPrintedObject[] = objects.filter(obj => (obj.isHole && obj.isTransparent))
        if(errObjects.length > 0) ret.push({text: "ERROR : Objects musnt be hole and transparent simultaneously.", type: AdditionalNotesTypesEnum.Error})
        
        objects.forEach((obj, i) => {
            
            //BEYOND THE GLASS//
            //Left and right
            if(obj.positioning.centralOffsetX + obj.width > glass.width || obj.positioning.centralOffsetX < 0) {
                ret.push({text: `ERROR: object ${obj.title ? `"${obj.title}"` : i+1} is beyond the glass`, type: AdditionalNotesTypesEnum.Error})
                return
            }

            if(obj.positioning.centralOffsetY + (obj.height) > glass.height) {
                ret.push({text: `ERROR: object ${obj.title ? `"${obj.title}"` : i+1} is beyond the glass`, type: AdditionalNotesTypesEnum.Error})
                return
            }

            if(obj.positioning.centralOffsetY < 0) {
                ret.push({text: `ERROR: object ${obj.title ? `"${obj.title}"` : i+1} is beyond the glass`, type: AdditionalNotesTypesEnum.Error})
                return
            }

            //INSIDE PRINTED BORDER//
            if(borderSol){ 
                if(obj.positioning.centralOffsetY + obj.height > borderSol.t && obj.positioning.centralOffsetY < glass.height - borderSol.b){
                                
                    if(obj.positioning.centralOffsetX + obj.width > borderSol.l && obj.positioning.centralOffsetX < glass.width - borderSol.r) {
                        ret.push({text: `ERROR: object ${obj.title ? `"${obj.title}"` : i+1} is not on the pritned border`, type: AdditionalNotesTypesEnum.Error})
                        return
                    }
                }
            }
        })
    }
    return ret
}

export function cutoutsAdditional(customization : IGlassCustomization | null) : IAdditionalNotes | undefined {
    if (customization && customization.cutouts) {

        return { text: `NOTE: + ${customization.cutouts} cutouts`, type: AdditionalNotesTypesEnum.Note }
    }else return undefined   
}

export function holesAdditional(customization: IGlassCustomization) : IAdditionalNotes | undefined {
    if(customization.holes) {
        return { text: `NOTE: + ${customization.holes} holes`, type: AdditionalNotesTypesEnum.Note }
    }

    return undefined
}

export function tailErrorsAdditional(tail: ITail | undefined, sensor : ISensor) : IAdditionalNotes[] {
    const errors : IAdditionalNotes[] = []
    if(sensor.withSensor !== true) return errors
    if(tail) {
        const comparedLength = ((tail.attachment === TailAttachment.Top || tail.attachment === TailAttachment.Bottom) ? 
            sensor.activeArea.width + sensor.passiveArea.r + sensor.passiveArea.l : sensor.activeArea.height + sensor.passiveArea.t + sensor.passiveArea.b)
        if(tail.width > comparedLength) {
            errors.push({text: "ERROR: Tail is too wide", type: AdditionalNotesTypesEnum.Error})
        }
        const activeBounds = (tail.attachment === TailAttachment.Top || tail.attachment === TailAttachment.Bottom) ? {
            min: -(sensor.activeArea.width/2) + sensor.offset.x - sensor.passiveArea.r,
            max:  (sensor.activeArea.width/2) + sensor.offset.x + sensor.passiveArea.l
        } : {
            min: -(sensor.activeArea.height/2) + sensor.offset.y - sensor.passiveArea.t,
            max:  (sensor.activeArea.height/2) + sensor.offset.y + sensor.passiveArea.b
        }

        if((tail.centralOffset + tail.width/2 > activeBounds.max || tail.centralOffset - tail.width/2 < activeBounds.min ) && !(tail.width > comparedLength)) {
            errors.push({text: "ERROR: Tail is too offset", type: AdditionalNotesTypesEnum.Error})
        }
    }

    return errors
}

export function notesAdditional(notes : string) : IAdditionalNotes | undefined {
    if ((notes.trim().length > 0 ? 'OTHER NOTES: ' + notes : undefined) !== undefined) {
        return { text: ('OTHER NOTES: ' + notes), type: AdditionalNotesTypesEnum.Note }
    } else return undefined
}

export function glassSensorAdditional(glass: Glass | undefined, sensor: Sensor | undefined, activeArea: ActiveArea | undefined, printedBorder: PrintedBorder | undefined) : IAdditionalNotes[] {
    const ret : IAdditionalNotes[]= [];
    if(!glass) return ret
    if(sensor && activeArea) {    
        if (isLargerThan(sensor.shape, glass.shape))
            ret.push({text: "ERROR: The sensor extends beyond the glass.", type: AdditionalNotesTypesEnum.Error})


        if (isLargerThan(activeArea.shape, glass.shape))
            ret.push({text: "ERROR: The active areas extends beyond the glass.", type: AdditionalNotesTypesEnum.Error})
    }    

    if (!printedBorder)
        ret.push({text: "WARNING: There is no printed border.", type: AdditionalNotesTypesEnum.Warning})
    else if (activeArea && isLargerThan(printedBorder.shape, activeArea.shape))
        ret.push({text: "WARNING: The active area is smaller than the unprinted area.", type: AdditionalNotesTypesEnum.Warning})

    return ret
}

/**
 * Comparing constraint of all form field (min, max, step...) with form solution and returning all errors
 * Should be none, since form doesnt let you make these errors.
 * @param solutionFields Array of form fields to know their min, max, step, integer...
 * @param enitreSolution Solution to compare if all form field followed their constraints
 * @returns Error msgs of which form field did not follow their constraints
 */
export function solutionErrors(solutionFields?: ISolutionField[], enitreSolution? : ISolution): IAdditionalNotes[] {
    const errors : IAdditionalNotes[]= [];

    if(solutionFields) {
        let fieldMsg = ""
        solutionFields.forEach((field : ISolutionField) => {
            
            if(field.type === "number" || field.type === "rawNumber") {
                
                const val = getValue(enitreSolution, field.name)
                if(getComponent(field.name) === "glass" && !enitreSolution?.glass.withGlass) return
                if(getComponent(field.name) === "sensor" && !enitreSolution?.sensor.withSensor) return
                if(field.max !== undefined) {
                    if(val >= field.max) fieldMsg += `${normalizeFieldName(field.name)} is too high\n`
                }
                if(field.min !== undefined) {
                    if(val <= field.min) fieldMsg += `${normalizeFieldName(field.name)} is too low\n`
                }
                if(field.integer === true) {
                    if(Math.round(val) !== val) fieldMsg += `${normalizeFieldName(field.name)} has to be integer\n`
                }
            }
        })
        if(fieldMsg.length > 0) {
            fieldMsg = "ERROR: " + fieldMsg
            errors.push({text: fieldMsg, type: AdditionalNotesTypesEnum.Error})
        }
    }
    return errors;
}

function normalizeFieldName(fieldName : string) : string {
    return fieldName.split(".").join(" ")
}

function getComponent(fieldName: string) : string {
    return fieldName.split(".")[0]
}

function getValue(object : any, path : string) {
    const properties = path.split('.');
    let value = object;
    for (const property of properties) {
      if (value && value.hasOwnProperty(property)) {
        value = value[property];
      } else {
        return undefined; // Neexistující vlastnost
      }
    }
    return value;
}