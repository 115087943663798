import { ColorContext, FontContext } from "./Common";
import { Arrow } from "./Arrow";
import { Label } from "./Label";
import { IRoundedRectShape, getCorners, getRadius } from "../models/Rectangle";
import * as math from "../models/Math";
import { useContext } from "react";
import { IComponentInfo } from "../components/Base";
import { floatToStr } from "../models/Common";
import React from "react";


export interface IRadiusProps {
	measuredShape: IRoundedRectShape,
	measuredInfo: IComponentInfo,
    arrowLength: number,
}
  
export function Radius({ measuredShape: shape, measuredInfo: info, arrowLength }: IRadiusProps) {
    const corners = getCorners(shape);
    const radius = getRadius(shape);
    const radii = [ radius.tl, radius.tr, radius.br, radius.bl ];
    const sqrt2 = Math.sqrt(2);
    const sqrt22 = sqrt2 / 2;
    const arrowsVecs = [[-sqrt22, -sqrt22], [sqrt22, -sqrt22], [sqrt22, sqrt22], [-sqrt22, sqrt22]] as const;
    const cornerInsets = radii.map(r => r * (sqrt2 - 1)); // radius * (unit square diagonal - unit circle radius)

    const { size: fontSize } = useContext(FontContext);

	return <>
        <ColorContext.Provider value={{ color: info.color }}>
            {radii.map((r, i) => {
                const from = math.plus(corners[i], math.mult(-cornerInsets[i], arrowsVecs[i]));
                const to = math.plus(corners[i], math.mult(arrowLength, arrowsVecs[i]));
                const angle = math.toDeg(math.signedAngleBetween([1, 0], arrowsVecs[i]));
                const labelPos = math.plus(to, math.mult(fontSize, math.perpendicular(arrowsVecs[i]))); // offset label perpendicullary from arrow
                if(r > 0) return <g key={i}>
                    <Arrow from={[...from]} to={to} start />
                    <Label position={labelPos} rotation={angle} textAnchor="end">R{floatToStr(r)}</Label>
                </g>
                return <></>
            })}
        </ColorContext.Provider>
	</>
}