import { ISolution } from "./Solution";
import { ISolutionField } from "./SolutionFields";

export interface ISolutionRule {
    controlFields: ISolutionField['name'][],
    controlledFields: ISolutionField['name'][],
    apply: (solution: ISolution) => void, // changes happen in place
}



// The rules are applied in the order of listing.
// A rule works with the results of previous rules
// -- the latest solution values.
export const solutionRules = [
] as ISolutionRule[];

export function findRules(field: ISolutionField['name']) {
    return solutionRules.filter(r => r.controlFields.includes(field));
}
