import { findField } from "../models/SolutionFields";
import { Field as FormikField, getIn } from "formik";
import { createContext, useContext } from "react";
import { IRange, ISolutionConfig, isRange } from "../models/Solution";
import { floatStep, parseInt } from "../models/Common";
import React from "react";
import { StyledItemMuiFiled } from "./muiFields/StyledSelectMuiField";



export const SolutionConfigContext = createContext({ fixedValues: {}, constraints: {}} as ISolutionConfig);

export interface IFieldProps<V = any> {
    name: string,
    alternative?: string,
    disabled?: boolean,
    values?: V[],
    valueToString?: (value: V) => string,
}

export function SolutionField({ name, alternative, disabled: locallyDisabled, values, valueToString }: IFieldProps) {
    const { fixedValues, constraints } = useContext(SolutionConfigContext);

    const fieldProps = findField(name, alternative);
    if (!fieldProps)
        return null;

    const disabled = getIn(fixedValues, name) !== undefined || locallyDisabled;
    const constraint = getIn(constraints, name);

    let numberProps: Partial<IRange<number>> & { step?: number } | undefined;
    numberProps = constraint && isRange(constraint) ? constraint : undefined;
    const allowedValues = constraint && Array.isArray(constraint) ? constraint : undefined;

    if (fieldProps.type === 'number' && !fieldProps.allowNegative) {
        if (numberProps === undefined)
            numberProps = { min: 0 };
        if (numberProps.min === undefined)
            numberProps.min = 0;
    }
    if (fieldProps.type === 'number') {
        const step = fieldProps.parse === parseInt ? 1 : floatStep;
        if (numberProps === undefined)
            numberProps = { step };
        else
            numberProps.step = step;
    }

    if (!allowedValues && !values && !fieldProps.values)
        return <FormikField {...fieldProps} disabled={disabled} inputProps={numberProps} />;
    
    const valToStr = valueToString ?? fieldProps.valueToString;

    
    return <FormikField {...fieldProps} disabled={disabled}>
        {(allowedValues ?? values ?? fieldProps.values)!.map((value) =>
            StyledItemMuiFiled({key:value, value:value, valToStr:valToStr}),
        )}
    </FormikField>;
}