import { HTMLInputTypeAttribute } from "react";
import MuiField from "../form/MuiField"
import { FieldProps } from "formik";
import { allAttachments, TailAttachment } from "./Sensor";
import { ProductType, ProductUsage, productTypes, productUsages } from "./Product";
import { EnumType, parseEnum, parseInt } from "./Common";
import { AntiGlareType, antiGlareTypes, surfaceToStr, surfaces, parseSurface } from "./Customization";
import { CheckboxField } from "../form/Checkbox";

export interface ISolutionField<V = any> {
    name: string,
    alternative?: string,
    label: string,
    helperText?: string,
    variant?: 'select' | 'textarea' | 'notes',
    type?: HTMLInputTypeAttribute,
    parse?: (str: string) => V,
    valueToString?: (value: V) => string,
    component: (props: FieldProps) => JSX.Element, 
    values?: EnumType,
    allowNegative?: boolean,
    min?: number,
    max?: number,
    integer: boolean
}

export interface ISolutionFieldId {
    name: ISolutionField['name'],
    alternative: ISolutionField['alternative'],
}

export const solutionFields = [
    //PAGE 1
    { name: "product.type", label: "Type", variant: "select", component: MuiField, parse: (s: string) => parseEnum(ProductType, s), values: productTypes },
    { name: "product.usage", label: "Usage", variant: "select", component: MuiField, parse: (s: string) => parseEnum(ProductUsage, s), values: productUsages },
    { name: "product.pieces", label: "Pieces", type: "number", component: MuiField, parse: parseInt, min:0, integer:true },
    { name: "product.title", label: "Product title", variant: "textarea", component: MuiField, helperText: "Product title"},
    { name: "product.drawingNumber", label: "Drawing number", variant: "textarea", component: MuiField, helperText: "Drawing number" },

    //PAGE 3
    { name: "glass.width", label: "Width (mm)", type: "number", component: MuiField, step:10, max: 10000, min: 0 },
    { name: "glass.height", label: "Height (mm)", type: "number", component: MuiField, step:10, max:5000, min: 0 },
    { name: "glass.thickness", label: "Thickness (mm)", variant: "selectThickness", component: MuiField, step:1, min: 0, max: 100},
    { name: "glass.coverGlass[0]", label: 'Coverglass 1', type: "number", paired: true, component: MuiField,  min: 0 },
    { name: "glass.coverGlass[1]", label: 'Coverglass 2', type: "number", paired: true, component: MuiField,  min: 0 },
    { name: "glass.coverGlass[2]", label: 'Coverglass 3', type: "number", paired: true, component: MuiField,  min: 0 },

    //PAGE 4
    ////corners
    { name: "customization.corners.tr", label: "Top right", type: "rawNumber", component: MuiField, min: -0.01},
    { name: "customization.corners.tl", label: "Top left", type: "rawNumber", component: MuiField, min: -0.01},
    { name: "customization.corners.br", label: "Bottom right", type: "rawNumber", component: MuiField, min: -0.01},
    { name: "customization.corners.bl", label: "Bottom left", type: "rawNumber", component: MuiField, min: -0.01},
    ////cutouts and holes
    { name: "customization.cutouts", label: "Number of cutouts", type: "number", component: MuiField, parse: parseInt, min: -1, integer:true },
    { name: "customization.holes", label: "Number of holes", type: "number", component: MuiField, parse: parseInt, min: -1, integer:true },
    ////surfce
    { name: "customization.surface", label: "Surface", variant: "selectSurface", component: MuiField, values: surfaces, parse: parseSurface, valueToString: surfaceToStr },
    { name: "customization.antiGlareType", label: "Anti-glare type", variant: "select", component: MuiField, values: antiGlareTypes, parse: (s: string) => parseEnum(AntiGlareType, s) },
    ////other settings
    { name: "customization.extraLayers", label: "Thickness of protective layers (mm)", type: "number", component: MuiField, min: -0.01, step: 0.1 },

    //PAGE 4
    { name: "printing.frameThickness.l", label: "Left", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "printing.frameThickness.r", label: "Right", type: "rawNumber", component: MuiField, min: -0.01 }, 
    { name: "printing.frameThickness.t", label: "Top", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "printing.frameThickness.b", label: "Bottom", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "printing.color", label: "Color of printing", type: "text", component: MuiField, helperText:"color"},
    { name: "printing.doublePrint", label: "Two layers of printing", type:"checkbox", component: CheckboxField },
    
    //PAGE 5
    ////diagonal
    { name: "sensor.diagonal", label: "Diagonal (inches)", type: "number", component: MuiField, min: 0 },
    ////active are
    { name: "sensor.activeArea.width", paired:true, label: "Width", type: "number", component: MuiField, step:10, min: 0, max:10000 },
    { name: "sensor.activeArea.height", paired:true, label: "Height", type: "number", component: MuiField, step:10, min: 0, max:5000 },
    ////thickness
    { name: "sensor.thickness", label: "Thickness (mm)", type: "number", component: MuiField, min: 0, step: 0.1 },
    ////passive area
    { name: "sensor.passiveArea.l", label: "Left", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "sensor.passiveArea.r", label: "Right", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "sensor.passiveArea.t", label: "Top", type: "rawNumber", component: MuiField, min: -0.01 },
    { name: "sensor.passiveArea.b", label: "Bottom", type: "rawNumber", component: MuiField, min: -0.01 },
    ////offset
    { name: "sensor.offset.x", paired: true, label: "X offset", component: MuiField, type:"number", integer: true },
    { name: "sensor.offset.y", paired: true, label: "Y offset", component: MuiField, type:"number", integer: true },
    ////tail
    { name: "sensor.tail.attachment", paired:true, label: "Attachment", variant: "select", component: MuiField, parse: (s: string) => parseEnum(TailAttachment, s), values: allAttachments },
    { name: "sensor.tail.width", paired:true, label: "Width", type: "number", component: MuiField, step:5, min:9.99 },
    { name: "sensor.tail.length", paired:true, label: "Length", type: "number", component: MuiField, step:5, min:-0.01 },
    { name: "sensor.tail.centralOffset", paired:true, label: "Alignment", helperText: "Zero = center, negative = move left, positive = move right", type: "number", component: MuiField , step:5 },
    ////cable
    { name: "sensor.cable.height", paired:true, label: "Length", type: "number", component: MuiField, step:5, min: 19.99 },

    //PAGE 7
    { name: "customer.company", label: "Company", type: "text", component: MuiField, helperText: "your company name" , required: true},
    { name: "customer.creator", label: "Name", type: "text", component: MuiField, helperText: "your name", required: true},
    { name: "customer.email", label: "E-mail", type: "email", component: MuiField, helperText: "your@email.com", required: true},
    { name: "product.notes", label: "Notes", variant: "notes", component: MuiField, helperText: "notes" },

] as ISolutionField[];

export function findField(name: string, alternative?: string) {
    return solutionFields.find(f => f.name === name && (!alternative || f.alternative === alternative));
}
