import { RoundedRect } from "../drawing/Rectangle";
import { LocationDirection, Side } from "../models/Common";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";
import { Sensor } from "./Sensor";

export class SensorProfile extends BaseComponent {

    public constructor(sensor: Sensor, thickness: number, glassHeight: number, centralOffsetX: number, glassProfileH: number, extraLayersProfileH : number) {
        super({
            id: sensor.info.id,
            color: "#00ccff",
            description: "",
        } as IComponentInfo,
        {
            width: glassHeight,
            height: thickness,
            centralOffsetX: centralOffsetX,
            centralOffsetY: -(glassProfileH + thickness )/2 - extraLayersProfileH
        } as IComponentShape,
        {
            dimensions: {
                sides: [ Side.Right ],
                diff: true,
            },
            id: {
                locationDirection: LocationDirection.Outward,
                fromSide: Side.Top,
                padding: 10,
            },
            description: false,
        } as IComponentLabels);
    }

    override render() {
      return <>
          <RoundedRect shape={this.shape} info={this.info} />
      </>;
    }
}