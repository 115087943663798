import { Button, Grid, IconButton } from "@mui/material"
import { ChangeEvent } from "react"
import { StyledMuiFieldLabel } from "./muiFields/MuiFIeldLabel"
import { DeleteOutline } from "@mui/icons-material"

export function TailDetailInput(props: {
  setDetail: (newTail : string|null) => void
  setImgName: (newImgName: string | null) => void
  imgName: string | null
}): JSX.Element {
    return (
    <Grid container alignItems="center">
          
        <Grid item xs={12} md={5}>

            <StyledMuiFieldLabel paired={true} label={"Detail"} />
        </Grid>  
          
        <Grid item xs={12} md={7} justifyContent="space-between" direction="row">
            <Button
                sx={{
                    alignItems: "start",
                    overflow: "hidden",
                    width: "75%",
                    color: "#3e3259",
                    backgroundColor: "white",
                    border: "1px solid #dfe0f2",
                    borderRadius: "14px",
                    maxHeight:"33px",
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: "0px solid black",
                    },
                    '& .MuiOutlinedInput-root': {
                        color: "#250048",
                        padding:"0px",
                        borderRadius: "14px",
                        '&.Mui-focused fieldset': {
                        borderColor: '#1a0549',
                        },
                    },
                    '& .MuiOutlinedInput-input': {
                        padding:"5px 10px 5px 10px"
                    },            
                    '& .Mui-error': {
                        border: "1px solid red",
                    },
                    input: {
                        '&:invalid': {
                            borderRadius: "14px",
                            border: "1px solid red"
                        }
                    }
                }}
                onClick={() => {document.getElementById('getFile')?.click()}}
            >
                {props.imgName === null ? "No file selected" : props.imgName}
            </Button>
            <IconButton 
                onClick={() => {props.setDetail(null); props.setImgName(null)}}
            >
                <DeleteOutline sx={{ color: "#250048" }} />
            </IconButton>
            <input
                id="getFile"
                style={{display:"none"}}
                type="file" 
                accept=".jpg, .jpeg, .png, .gif, .svg"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if(event.target.files && event.target.files[0]) {
                    const file = event.target.files[0]
                    const reader = new FileReader()
                    reader.onloadend = () => {
                        props.setDetail(reader.result as string)
                    }
                    reader.readAsDataURL(file)

                    props.setImgName(event.target.files[0].name)
                }else {
                    props.setDetail(null)
                    props.setImgName(null)
                }
                }}
                className="filetype"
            />
        </Grid>
    </Grid>
    )
}