import { RoundedRect } from "../drawing/Rectangle";
import { LocationDirection, Side } from "../models/Common";
import { ISolution } from "../models/Solution";
import { BaseComponent, IComponentInfo, IComponentLabels, IComponentShape } from "./Base";
import { GlassProfile } from "./GlassProfile";

export class ExtraLayersProfile extends BaseComponent {


  public constructor(id: number, solution: ISolution, glassPorfile: GlassProfile, centralOffsetX: number, glassProfileH: number) {

    const description = "Protective layer: " + 
      (solution.customization.otherSettings.UV ? "UV protection, " : "") +
      (solution.customization.otherSettings.IR ? "IR (solar) protection, " : "") +
      (solution.customization.otherSettings.PF ? "Protective foil, " : "") +
      `${solution.customization.extraLayers.toFixed(1)} mm`
    super({
      id,
      color: "#7d37c0",
      description: description
    } as IComponentInfo,
    {
      width: glassPorfile.shape.width,
      height: solution.customization.extraLayers,
      centralOffsetX: centralOffsetX,
      centralOffsetY: -(solution.customization.extraLayers + glassProfileH)/2
    } as IComponentShape, // overall shape
    {
      dimensions: {
        sides: [ Side.Right ],
        diff: false,
      },
      id: {
        locationDirection: LocationDirection.Outward,
        fromSide: Side.Left,
        padding: 10,
      },
      description: false,
    } as IComponentLabels)
  }
  
  override render() {
    return <>
        <RoundedRect shape={this.shape} info={this.info} />
    </>;
  }
}