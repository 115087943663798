import { Typography } from "@mui/material";

interface ILabelProps {
  paired : boolean,
  label : string
}

export function StyledMuiFieldLabel(props : ILabelProps) : JSX.Element {
  const size = props.paired ? "80%" : "100%"
  const weight = props.paired ? "400" : "500"

  return <Typography paddingRight="20px" color="#250048" fontSize={size} fontWeight={weight} maxWidth="200px" maxHeight="33px">{props.label}</Typography>
}