import { IGlass } from "../models/Glass";
import { IPrintedObject } from "../models/Printing";
import { IRoundedRectShape } from "../models/Rectangle";
import { BaseComponent, IComponentInfo, IComponentLabels } from "./Base"
import { Glass } from "./Glass";


export class PrintedLogo extends BaseComponent{
    public constructor(id: number, solutionOfLogo: IPrintedObject, glass: Glass) {

        const isC = solutionOfLogo.isCircle
        const heightOfLogo = isC ? Number(solutionOfLogo.radius*2) : Number(solutionOfLogo.height)
        const widthOfLogo = isC ? Number(solutionOfLogo.radius*2) : Number(solutionOfLogo.width)
        const radiusOfLogo = isC ? Number(solutionOfLogo.radius) : null

        const offsetY = -glass.shape.height/2 + heightOfLogo/2 + solutionOfLogo.positioning.centralOffsetY
        const offsetX = -glass.shape.width/2 + widthOfLogo/2 + solutionOfLogo.positioning.centralOffsetX
        const description = solutionOfLogo.title

        super({
            id,
            color: "#000",
            description,
            img: solutionOfLogo.wImg? solutionOfLogo.file : null
        } as IComponentInfo,
        {
            width: widthOfLogo,
            height: heightOfLogo,
            centralOffsetX: offsetX,
            centralOffsetY: offsetY,
            radius : radiusOfLogo,
            drawNoRadii: true
        } as IRoundedRectShape,
        {
            dimensions: {
                sides: [  ],
                diff: false,
            },
            description: false,
        } as IComponentLabels);
    }
}