import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { setPageTitle } from "./App";
import { ISidePadding, ISideOffsets, Orientation, Side } from "./models/Common";
import { componentWantsNoRaddiRendered, getBounds, isNonZero, isRoundedRect } from "./models/Rectangle";
import { Dimensions } from "./drawing/Dimensions";
import { FontContext, ScaleContext, getDrawingInfo, getInfo, IArea } from "./drawing/Common";
import { Radius } from "./drawing/Radius";
import { Id } from "./drawing/Id";
import { Glass } from "./components/Glass";
import { GlassProfile } from "./components/GlassProfile";
import { Sensor } from "./components/Sensor";
import { ActiveArea } from "./components/ActiveArea";
import { PrintedBorder } from "./components/PrintedBorder";
import { FlexibleCable } from "./components/FlexibleCable";
import { Sticker } from "./components/Sticker";
import { SolutionContext } from "./models/Form";
import PDFDocument from "pdfkit";
import SVGtoPDF from "svg-to-pdfkit";
import './pdfkit-webpack/registerStaticFiles';
import { Descriptions } from "./drawing/Descriptions";
import { Box, Button } from "@mui/material";
import { InfoTable } from "./drawing/InfoTable";
import { solutionErrors, cutoutsAdditional, notesAdditional, objectNotesAdditional, objectErrorsAdditional, glassSensorAdditional, tailErrorsAdditional, holesAdditional } from "./components/Additionals";
import { fillSolutionsEmpty } from "./form/SolutionFill";
import { PrintedLogo } from "./components/PrintedLogo";
import React from "react";
import { AdditionalText } from "./drawing/AdditionalText";
import { IAdditionalNotes } from "./models/AdditionalText";
import { solutionFields } from "./models/SolutionFields";
import MontserratFont from './Montserrat-Regular.ttf';
import { Base64Encode } from "base64-stream"
import { SensorProfile } from "./components/SensorProfile";
import { useParams } from "react-router-dom";
import { BaseComponent } from "./components/Base";
import { ProductNotes } from "./drawing/ProductNotes";
import { ExtraLayersProfile } from "./components/ExtraLayersProfile";
import { ProductType } from "./models/Product";
import { ProfileThickness } from "./components/ProfileThickness";

interface IDrawingProps {
    admin: boolean,
    renderEmpty: boolean
    width : number
}

// Define the methods exposed by Drawing
export interface DrawingHandle {
    getPDF: () => Promise<string>;
}

const Drawing = forwardRef<DrawingHandle, IDrawingProps>(function Drawing({ admin, renderEmpty, width }: IDrawingProps, ref) {

    const { identifier } = useParams();
    const [ detailDim, setDetailDim ] = useState<{height: number, width: number}>({height: 500, width: 500})
    const [ detailPosX, setDetailPosX ] = useState<number>(750)
    const [solution, setData] = useState(useContext(SolutionContext));
    const [fontBuffer, setFontBuffer] = useState<ArrayBuffer | undefined>(undefined)
    const fontSize = 9/1000 * width;
    let canvasW = width
    let canvasH = (canvasW / Math.sqrt(2)) //793
    const test = false /////DONT BUILD WITH TRUE
    
    useEffect(() => { 
        const ugh = async () => {
            setFontBuffer(await fetch(MontserratFont).then(res => res.arrayBuffer()))
        }
        ugh()
    }, [])

    const downloadFromDrawing = () => {

        if (!svgRef.current) {
            return;
        }


        const wTmp = canvasW
        const hTmp = canvasH
        canvasW = 1122
        canvasH = canvasW / Math.sqrt(2);
        var finalString = ''
        
        const doc = new PDFDocument({ size: "A4", layout: "landscape" });
        var stream = doc.pipe(new Base64Encode());
        stream.on('data', function(chunk) {
            finalString += chunk;
        });
        stream.on('end', function() {
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${finalString}`;
            link.download = 'Technical-drawing-test-download';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });

        doc.registerFont("Montserrat", fontBuffer)
        SVGtoPDF(doc, svgRef.current, 0, 0, { useCSS: true });
        doc.end();

        canvasW = wTmp
        canvasH = hTmp
    };

    const getPDF = () => {return new Promise<string>((resolve, reject) => {
        if (svgRef.current === null) {
            console.log('SVG is null')
            reject(new Error("SVG element is null"))
            return null
        }
        console.log('Generating PDF...');
        let base64str = ""
        const doc = new PDFDocument({ size: "A4", layout: "landscape" });
        var stream = doc.pipe(new Base64Encode());
        stream.on('data', function(chunk) {
            base64str += chunk;
        });
        stream.on('end', function() {
            //console.log(base64str)
            resolve(base64str)
        });

        if(fontBuffer) {
            doc.registerFont("Montserrat", fontBuffer)
        }
        SVGtoPDF(doc, svgRef.current, 0, 0, { useCSS: true });
        doc.end();
    })}

    useImperativeHandle(ref, () => ({
        getPDF
    }));
    
    useEffect(() => {
        if(solution.sensor.cable.detail === null) return
        const img = new Image()
        img.src = solution.sensor.cable.detail
        img.onload = () => {
            const {height, width} = getHW(
                img.naturalHeight, img.naturalWidth, solution.product.notes !== ""
            )
            setDetailDim({height: height, width: width})
            const x = .99*canvasW - width 
            setDetailPosX(x)
        }

        const getHW = (naturalH: number, naturalW: number, notes: boolean) => {
            const maxW = 0.35 * canvasW
            const maxH = (!notes ? 0.35 : 0.2) * canvasW

            if (naturalH <= maxH && naturalW <= maxW) {
                return {height: naturalH, width: naturalW}
            }

            const scaleH = maxH / naturalH
            const scaleW = maxW / naturalW
            const scale = Math.min(scaleH, scaleW);
            const H = naturalH * scale
            const W = naturalW * scale
            return { height: H, width: W }
        }
    }, [solution.sensor.cable.detail, solution.product.notes, canvasW])

    /*
    useEffect(() => {
        setPageTitle("Drawing")
        const fetchData = async (identifier: string) => {
            console.log('Loading drawing', identifier)
            const result = await fetch(`/api/drawing/${identifier}`)
            if (result.status === 200) {
                const data = await result.json()
                console.log(data)
                setData(data)
            }
        }
        if (identifier !== undefined) {
            fetchData(identifier)
        }
    }, [identifier]);
    */
    const svgRef = useRef<SVGSVGElement>(null);
    const downloadRef = useRef<HTMLAnchorElement>(null);


    fillSolutionsEmpty(solution)

    console.log(solution)

    const components : Array<BaseComponent> = []

    const withGlass = solution.glass.withGlass
    const withPrinting = solution.printing.withPrinting === true && withGlass
    const withObject = solution.printing.withLogo === true && withPrinting
    const withExtraLayers = withGlass && solution.customization.extraLayers !== 0 && (
        solution.customization.otherSettings.IR ||
        solution.customization.otherSettings.UV ||
        solution.customization.otherSettings.PF
    )
    const withSensor = solution.sensor.withSensor;
    const typeO = solution.product.type === ProductType.FortouchO

    /*
               IDs (2x)
            1. glass
            2. printed border
            3. Extra layers
            4. sensor
            5. active area
            6. PET part
            7. Flex calbe
          8-n. Images
    */
    const printedBorder = withPrinting && withGlass ?
        new PrintedBorder(4, solution.printing.color || "RAL9005", solution.glass, solution.printing.doublePrint, solution.printing.frameThickness)
        :
        undefined
    if(printedBorder) {
        components.push(printedBorder)
    }

    const glass = withGlass ? new Glass(2, solution) : undefined
    if(glass) components.push(glass)

    const activeArea = withSensor ? new ActiveArea(10, solution, printedBorder, printedBorder?.shape) : undefined
    if(activeArea) components.push(activeArea)

    const sensor = activeArea ? new Sensor(8, solution, printedBorder?.shape ?? activeArea.shape, activeArea) : undefined
    if(sensor) components.push(sensor)
    
    const sticker = sensor ? new Sticker(12, solution, sensor) : undefined
    if(sticker) components.push(sticker)

    const flexCable = sticker ? new FlexibleCable(14, solution, sticker) : undefined
    if(flexCable) components.push(flexCable)

    if (withObject) {
        solution.printing.objects.forEach((obj, i) => {
            components.push(new PrintedLogo(i + 16, obj, glass!))
        })
    }

    components.sort((a, b) => a.info.id - b.info.id);

    const componentsWDimensions = components.filter(c => c.labels && c.labels.dimensions).sort((a, b) => a.info.id - b.info.id);
    const measureOffsets: ISideOffsets[] = [];



    const offsets = { t: -0.0265*width, b: 0.0265*width, l: -0.0265*width, r: 0.0265*width } as ISidePadding;

    for (let c of componentsWDimensions) {
        measureOffsets.push({ ...offsets });

        if (c.labels.dimensions?.sides.includes(Side.Top)) offsets.t -= 22;
        if (c.labels.dimensions?.sides.includes(Side.Bottom)) offsets.b += 22;
        if (c.labels.dimensions?.sides.includes(Side.Left)) offsets.l -= 22;
        if (c.labels.dimensions?.sides.includes(Side.Right)) offsets.r += 22;

    }

    const productNotesArea = solution.product.notes === "" ? 
        {width: 0, height: 0} as IArea
        :
        { width: Math.max(0.25*canvasW, detailDim.width), height: 50 + 0.15 * canvasW, offsetX: canvasW - Math.max(0.25*canvasW, detailDim.width), offsetY: 140 + detailDim.height } as IArea

    const imageNotesW = Math.max(
        productNotesArea.width,
        (solution.sensor.cable.detail == null || !solution.sensor.withSensor) ? 0 : detailDim.width
    )
    const frontShift = imageNotesW === 0 ? 0 : imageNotesW + 0.05 * canvasW

    const drawingFrontArea = { width: canvasW - frontShift, height: 0.6 * canvasH, offsetX: 0, offsetY: 20 } as IArea;

    const drawingProfileArea = { width: drawingFrontArea.width, height: 0.16 * canvasH, offsetX: drawingFrontArea.offsetX, offsetY: drawingFrontArea.height + drawingFrontArea.offsetY } as IArea;


    const notesArea = { width: 0.3 * canvasW, height: canvasH - (drawingProfileArea.height + drawingProfileArea.offsetY), offsetX: 20, offsetY: drawingFrontArea.height + drawingProfileArea.height } as IArea;


    const infoTableArea = { width: 0.65 * canvasW - 20, height: 0.24 * canvasH - 20, offsetX: 0.35 * canvasW, offsetY: drawingFrontArea.height + drawingProfileArea.height } as IArea;

    const { scale: scaleFront, drawingBounds, componentBounds, drawingOrientation } = getDrawingInfo(components, drawingFrontArea, { t: fontSize, l: fontSize, r: fontSize, b: fontSize }, offsets);
    const { scale: scaleProfile } = getInfo(scaleFront, drawingProfileArea, { t: 0, l: 0, r: 0, b: 0 });


    const profileOffset = -0.5 * (componentBounds.x + componentBounds._x) - 0.5 * scaleFront.size.invert(offsets.r + offsets.l)
    
    
    const glassProfile = glass ? new GlassProfile(solution, glass, profileOffset) : undefined;
    if(glassProfile) components.push(glassProfile)
    const extraLayersProfile = glassProfile && withExtraLayers ? 
        typeO ?
            new ExtraLayersProfile(6, solution, glassProfile, glassProfile.shape.centralOffsetX || 0, -(glassProfile.dif || 0))
            :
            new ExtraLayersProfile(6, solution, glassProfile, glassProfile.shape.centralOffsetX || 0, glassProfile?.shape.height || 0)
        : 
        undefined
    if(extraLayersProfile) components.push(extraLayersProfile)
    const sensorProfile = sensor ? 
        typeO?
            new SensorProfile(sensor, solution.sensor.thickness, sensor.shape.width, profileOffset + (sensor.shape.centralOffsetX || 0), glassProfile?.shape.height || 0, 0) 
            :
            new SensorProfile(sensor, solution.sensor.thickness, sensor.shape.width, profileOffset + (sensor.shape.centralOffsetX || 0), glassProfile?.shape.height || 0, extraLayersProfile?.shape.height || 0) 
        :
        undefined
    if(sensorProfile) components.push(sensorProfile)

    
    const profileThickness = (
        (glassProfile && sensorProfile) || (glassProfile && extraLayersProfile)
    ) ? new ProfileThickness(glassProfile, extraLayersProfile, sensorProfile, typeO) : undefined


    const radiiComponents = components.filter(c => isRoundedRect(c.shape) && isNonZero(c.shape.radius ?? 0) && !componentWantsNoRaddiRendered(c.shape));

    components
        .filter(c => c !== glassProfile && c !== sensorProfile)
        .sort((a, b) => a.info.id - b.info.id)
        .forEach((c, i) => c.info.id = i + 1)
    if(glassProfile && glass) glassProfile.info.id = glass.info.id
    if(sensorProfile && sensor) sensorProfile.info.id = sensor.info.id

    const frontComponents = components.filter(
        (c) => c !== glassProfile && c !== sensorProfile && c !== extraLayersProfile
    )

    const describedComponents = components.filter(c => (c!.info.description !== "")).sort((a, b) => a.info.id - b.info.id)


    const allAdditionals = [
        ...solutionErrors(solutionFields, solution),
        ...glassSensorAdditional(glass, sensor, activeArea, printedBorder),
        ...objectErrorsAdditional(solution.printing?.objects, solution.glass, solution.printing?.frameThickness, solution.printing?.withLogo),
        ...objectNotesAdditional(solution.printing?.objects, solution.printing?.withLogo),
        cutoutsAdditional(solution.customization),
        holesAdditional(solution.customization),
        ...tailErrorsAdditional(solution.sensor.tail, solution.sensor),
        notesAdditional(solution.product.notes),
    ].filter(t => (t != null))


    if (renderEmpty) return (
        <Box width={canvasW} height={canvasH} style={{
            border: "1px solid #dfe0f2",
            borderRadius: "35px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#3e3259",
            flexDirection: "column"
        }}>
            <Box sx={{
                border: "3px solid #3e3259",
                borderRadius: "9999px",
                marginBottom: "20px",
                padding: "5px",
                height: "20px",
                width: "20px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: 24,
                fontWeight: "bold",
            }}>
                i
            </Box>
            <Box>
                fill in all the details and you will see your proposal here
            </Box>
        </Box>
    )
    return <FontContext.Provider value={{ size: fontSize }}>
        
        {test && <Box sx={{ p: 2, display: 'flex', columnGap: 1 }}>
            <Button variant="contained" onClick={() => downloadFromDrawing()}>Download from drawing</Button>

            
            <a ref={downloadRef} style={{ display: "none" }} download="technical-drawing.pdf" href="/#"> </a>
        </Box>}

        <svg ref={svgRef} width={canvasW} height={canvasH} style={{ border: "1px solid #dfe0f2", borderRadius: "35px" }}>

            <g id="drawing-front">
                <ScaleContext.Provider value={scaleFront}>
                    <text x={scaleFront.posX(drawingOrientation === Orientation.Landscape ? drawingBounds._x : drawingBounds._y)} y={2 * fontSize} textAnchor="middle" fontSize={fontSize} fill="#000">Front view</text>

                    <g className="components">
                        {frontComponents.map(c => c.render(canvasW))}
                    </g>
                    <g className="dimensions">
                        {componentsWDimensions.map((c, i) => c.labels.dimensions && <Dimensions key={i} measuredShape={c.shape} measuredInfo={c.info} referentialShape={glass?.shape || c.shape}
                            componentBounds={componentBounds} maxTickLength={15} offset={measureOffsets[i]} diff={c.labels.dimensions.diff} sides={c.labels.dimensions.sides} />
                        )}
                    </g>
                    <g className="ids">
                        {frontComponents.map((c, i) => c.labels.id && <Id key={i} shape={c!.shape} info={c!.info} {...c!.labels.id} />)}
                    </g>
                    <g className="radii">
                        {radiiComponents.map((c, i) => <Radius key={i} measuredShape={c!.shape} measuredInfo={c!.info} arrowLength={50} />)}
                    </g>
                </ScaleContext.Provider>
            </g>

            {solution.sensor.cable.detail != null && solution.sensor.withSensor &&
            <g id="drawing-detail">
                <text x={detailPosX} y={2 * fontSize} fontSize={fontSize} fill="#000">Flexible cable detail</text>
                <image 
                    height={`${detailDim.height}px`} href={solution.sensor.cable.detail}
                    x={`${detailPosX}px`}
                    y={"100px"}
                />
            </g>
            }

            {solution.product.notes !== "" &&
                <g id="notes">
                    <ProductNotes area={productNotesArea} fontSize={1.5*fontSize} fill="#000" text={solution.product.notes} />
                </g>
            }

            <g id="drawing-profile"> {(glassProfile || sensorProfile) && <> 
                <text x={scaleProfile.posX(getBounds(glassProfile ? glassProfile.shape : sensorProfile!.shape)._x)} y={drawingProfileArea.offsetY + 2 * fontSize} fontSize={fontSize} fill="#000">Side view{profileThickness ? `, ${Math.round(profileThickness.shape.height*100)/100} mm thick in total` :""}</text>
                <ScaleContext.Provider value={scaleProfile}>
                    {glassProfile && glassProfile.render()}
                    {extraLayersProfile && extraLayersProfile.render()}
                    {sensorProfile && sensorProfile.render()}

                    {[profileThickness, glassProfile, extraLayersProfile, sensorProfile].filter(c => c !== undefined).map( (c, i) =>
                        <Dimensions measuredShape={c!.shape} measuredInfo={c!.info} referentialShape={c!.shape} componentBounds={drawingBounds}
                        maxTickLength={15} offset={{ t: 0, b: 0, l: 0, r: -(45 + i * 22) }} diff={true} sides={c!.labels.dimensions?.sides ?? []} /> 
                    )}
                    
                    {glassProfile && glassProfile.labels.id && <Id shape={glassProfile.shape} info={glassProfile.info} {...glassProfile.labels.id} />}
                    {extraLayersProfile && extraLayersProfile.labels.id && <Id shape={extraLayersProfile.shape} info={extraLayersProfile.info} {...extraLayersProfile.labels.id} />}
                    {sensorProfile && sensorProfile.labels.id && <Id shape={sensorProfile.shape} info={sensorProfile.info} {...sensorProfile.labels.id} />}
                </ScaleContext.Provider>
                </>} </g>

            <FontContext.Provider value={{ size: fontSize * 1.5 }}>
                <g id="descriptions">
                    <Descriptions area={notesArea} components={describedComponents} />
                </g>
            </FontContext.Provider>

            <g id="info-table">
                <InfoTable area={infoTableArea} solution={solution} />
            </g>
        </svg>

        <Box>
            {allAdditionals.map((obj: IAdditionalNotes | undefined, i) => {
                if (obj === undefined) return <></>
                return <AdditionalText text={obj.text} type={obj.type} width={canvasW-30}/>
            })}
        </Box>
    </FontContext.Provider>;
}
)

export default Drawing;